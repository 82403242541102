


























import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import Storage from "@/util/Storage"
import {IndexStore} from "@/store/modules/Index";
import {Vue, Component} from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";
import SetType, {SetListType} from "@/views/Basics/Set/Set";
import {dropOut} from "@/views/Basics/Set/Server";


@Component({name: "Set", components: {HeadTop}})
export default class Set extends ZoomPage implements SetType {
    RightIcon = require("../../../assets/icon/Basics/Right.png");
    loadingShow = true
    List = [
        {
            path: "/userSetPass",
            title: "设置支付密码"
        }
    ]

    mounted() {
        this.loadingShow = false
    }

    handleToPath(data: SetListType) {
        if (data.path === "/userSetPass") {
            this.$router.push({
                path: data.path,
                query: {
                    PassBool: "true"
                }
            })
        }
    }

    handleClickOut() {
        dropOut().then(res=>{
            IndexStore.SetLoading(false);
            let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
            if ( bool != null && bool[0] == "micromessenger" ){
                window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7bcf48887c6bacf2&redirect_uri=https://mobile.31xiaoyuan.com&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect"
            }else{
                this.$router.replace({
                    path:"/register"
                })
            }
        })
    }
}
